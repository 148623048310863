import React, { memo, useState } from "react";
import WordModel from "../../common/WordModel";
import styles from "../../common/QuizzesModel.module.css";
import styles2 from "../quiz/Score.module.css";
import FullWordModel from "../../common/FullWordModel";

const ScoreModal = ({
  tanswers,
  dictionary,
  questions,
  openAswers,
  setOpenAnswers,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentquestion, setCurrentQuestion] = useState({});

  const handleClick = (e) => {
    setIsOpen(true);
    let foundItem = questions.find((item) => item.Id == e.split("|")[1]);
    setCurrentQuestion(foundItem);
  };

  const updateIsOpen = (o) => {
    setIsOpen(o);
  };

  const closeModal = () => {
    setOpenAnswers(false);
  };

  return (
    <>
      <div className={styles.modal_container}>
        {openAswers && (
          <div className={styles.modal}>
            <div className={styles.modal_content}>
              <div style={{ alignContent: "center", marginBottom: "10px" }}>
                <span className={styles.close} onClick={closeModal}>
                  &times;
                </span>

                <label
                  style={{
                    textDecoration: "underline",
                    fontWeight: "bold",
                    fontSize: "28",
                  }}
                >
                  {/* title */}
                </label>
              </div>

              <div className={styles2.dictionary}>
                <table className={styles2.dictionaryTable}>
                  <thead>
                    <tr>
                      <th>Your Answer</th>
                      <th>Actual Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(dictionary).map(([key, value]) => {
                      const userAnswer = value.toLocaleLowerCase();
                      const actualAnswer = key
                        .split("|")[2]
                        .toLocaleLowerCase();

                      return (
                        <tr key={key} className={styles2.dictionaryItem}>
                          {/* First column: User's answer */}
                          <td>
                            {userAnswer === actualAnswer ? (
                              <span className={styles2.correct}  onClick={() => handleClick(key)}>{value}</span> // Correct answer (green)
                            ) : (
                              <span className={styles2.incorrect}  onClick={() => handleClick(key)}>{value}</span> // Incorrect answer (red)
                            )}
                          </td>

                          {/* Second column: Actual answer */}
                          <td>
                            <span  onClick={() => handleClick(key)}
                              className={
                                userAnswer === actualAnswer
                                  ? styles2.correctActual // Green if correct
                                  : styles2.incorrectActual // Orange if incorrect
                              }
                            >
                              {key.split("|")[2]}{" "}
                              {/* Always show the actual answer */}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* <WordModel
        open={isOpen}
        updateIsOpen={updateIsOpen}
        currentquestion={currentquestion}
      /> */}

      <FullWordModel open={isOpen}
        updateIsOpen={updateIsOpen}
        selectedid={currentquestion.Id}/>
    </>
  );
};

export default memo(ScoreModal);
